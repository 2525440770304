import React, { Component  } from "react";
import Api  from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import apiHelp from "global/utils/funHelp";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";

class CusosActivosCerr extends HFormUser {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: true,
            swError:false,
        };
    }	
    componentDidMount() {this.readApi([]);} 
    readApi = (stParamDict)=>{
        this.post(Api.seccionesCerradosList,{qryFiltro:stParamDict})
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    startSearch = bus=>{ this.readApi(apiHelp.getTextSearch())} 
    startCrear= iReg => {this.edit(-1)};
    edit = row => {
      apiRuta.toRutaAdd(this,"Contenido",Api.materialConteListRoute,{ detKey: row.name }  )
    };       
    btMaterial = row => {
      apiRuta.toRutaAdd(this,"Contenido",Api.materialConteListRoute,{ detIdRs: row.name, detIdCurso:row.cur_id  })
    }; 
    btVolver = () => {apiRuta.toRutaBack(this)}        
      
	render() { 
	    if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                   { id:"ram_sigsec" , label:"Sigla"   }
                 , { id:"ram_descrip" , label:"Descripción"   }
                 , { id:"fab" , label:"Periodo"    ,align:"right",swFiltro:false}
                 , { id:"bt_Mate", label:"Contenidos",align:"center",accion:this.btMaterial ,clsIcono:"fas fa-file-word"}
                ]    
 		return (<ContainerRightTable 
                            tituloContainer='Mis Cursos Activos'
                            titulos={titulos}
                            resObj={this.state.resObj}
                            bt1={this.startCrear}
                            bt1Icon="fas fa-folder-plus"
                            bt1Class="btn-danger"
                            contexto={this}
                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(CusosActivosCerr);
import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";

class MaterialTreeHtml extends HFormUser {
    componentDidMount() { this.readApi(this.props.location.state.detKeyItem, 0); }
    getKey(keyPar, masmen) {
        return {
            idRs: this.props.location.state.detIdRs
            , idCurso: this.props.location.state.detIdCurso
            , idMateria: this.props.location.state.detIdMateria
            , idKey: keyPar
            , sumar: masmen
        }
    }
    readApi(keyPar, masmen) {
        //alert(1)
        this.setState({ loading: true })
        this.post(Api.materialViewId, this.getKey(keyPar, masmen))
            .then(data => { this.stateHarrys(this, data) })
    }
    btRefresh = () => { this.readApi(this.state.data.idmateria, 0) }
    btSiguiente = () => { this.readApi(this.state.data.idmateria, 1) }
    btAnterior = () => { this.readApi(this.state.data.idmateria, -1) }
    btVolver = () => { apiRuta.toRutaBack(this) }
    btPregunta = () => { apiRuta.toRutaBack(this) }
    btOk = () => {
        this.setState({ loading: true })
        this.post(Api.materialViewIdOkAlum, this.getKey(this.state.data.idmateria, 0))
            .then(data => {
                this.stateHarrys(this, data)
            })
    }

    render() {
        if (this.state.loading) return (<ShowLoading />)

        return (<>
            {this.fuBarraAvanza()}
            <ContainerRightEdit
                msgErr={this.state.resObj}
                styleCol12={{ width: '100%', height: '100%' }}
                tituloContainer={this.state.data && this.state.data.descripcion}
                bt1Click={this.btRefresh}
                bt1Icon="fas fa-sync"
                contexto={this}
            >

                {this.state.data && this.state.data.swokclase_cls != 1 && <div className="tab-content">
                    <div className="tab-empty">
                        <h2 className="display-4">ITEM, No Habilitado</h2>
                    </div>
                    <div className="tab-loading">
                        <div>
                            <h2 className="display-4">Tab is loading <i className="fa fa-sync fa-spin"></i></h2>
                        </div>
                    </div>
                </div>}
                {this.state.data && this.state.data.swokclase_cls == 1 &&
                    <form className="form-horizontal well" id="forms-registro">
                        <fieldset>
                            <div className="editor" style={{ marginLeft: '15px', marginRight: '15px' }}
                                dangerouslySetInnerHTML={{ __html: this.state.data.observ }}>
                            </div>
                        </fieldset>
                    </form>
                }
            </ContainerRightEdit>
        </>
        );
    }

    fuBarraAvanza() {
        return (<>
            <div className="card">
                <div className="card-header d-flex align-items-center" style={{ backgroundColor: "white", height: "50px" }}>
                    <div className="card-actions cursor-pointer ms-auto d-flex button-group" style={{ paddingTop: '15px' }}>
                        <div className="float-right ">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link link"
                                        onClick={this.btAnterior}
                                        aria-label="Previous">
                                        <span aria-hidden="true">
                                            <i class="ti ti-chevrons-left fs-4"></i>
                                        </span>
                                    </a>
                                </li>
                                {this.state.data && this.state.data.swokclase_cls == 1 && this.state.data.swokclase_reg != 1 &&
                                    (<><li class="page-item">
                                        <a class="page-link link"
                                            onClick={this.btOk}>
                                            <i className="far fa-lg fa-thumbs-up"></i>
                                        </a>
                                    </li>
                                        <li class="page-item">
                                            <a class="page-link link"
                                                onClick={this.btPregunta} >
                                                <i className="far fa-lg fa-hand-paper">
                                                </i></a>
                                        </li>
                                    </>)}

                                <li class="page-item">
                                    <a class="page-link link"
                                        onClick={this.btSiguiente}
                                        aria-label="Next">
                                        <span aria-hidden="true">
                                            <i class="ti ti-chevrons-right fs-4"></i>
                                        </span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>

        </>
        )
    }

}
export default WithNavigate(MaterialTreeHtml);
import React, { Component  } from "react";
import Api  from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable  from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CusosActConte extends HFormUser {
    componentDidMount() {this.readApi();}
    getKey = ()=>{
        return {idRs:this.props.location.state.detIdRs,idCurso:this.props.location.state.detIdCurso}
    }
    readApi = ()=>{
        //console.log("==============",Api.materialConteList,this.getKey())
        this.post(Api.materialConteList,this.getKey())
          .then(data=>{this.stateHarrys(this,data)})    
    }  
    startSearch = bus=>{ this.readApi()} 
    startCrear= iReg => {this.edit(-1)};
    edit = idReg => {/*
      this.props.history.push({pathname:Api.seccionesAlumnosRoute
                       ,state: { detKey: idReg }
                     });*/
    };       
    btMaterial = row => {
      let objKey = { detIdRs:this.props.location.state.detIdRs
                    ,detIdCurso:this.props.location.state.detIdCurso
                    ,detIdMateria: row.idmateria }
      apiRuta.toRutaAdd(this,"Material",Api.materialAvanceTreeRoute,objKey)
    };       
    btVolver = () => {apiRuta.toRutaBack(this)}
    
	  render() { 
	    if (this.state.loading) return (<ShowLoading/>)
        const titulos = [  
                   { id:"codigo" , label:"Sigla"   }
                 , { id:"descripcion" , label:"Descripción"   ,align:"left"}
                 , { id:"bt_Mate", label:"Material",align:"center",accion:this.btMaterial ,clsIcono:"fas fa-sitemap"}
                ]    
 		return (<ContainerRightTable 
                            tituloContainer='Contenido'
                            titulos={titulos}
                            resObj={this.state.resObj}
                            bt1={this.btVolver}
                            bt1Icon="fa fa-backward"
                            contexto={this}
                            >  
                </ContainerRightTable>  
            );
	 }

}	
export default WithNavigate(CusosActConte);
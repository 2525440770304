import Api from "modAlumno/ApiDocente";
const _menu =
{
    name: 'Dashboard',
    arMenu : [
        {
            titulo: "Mis Cursos", icon: "fa fa-bug"
            , menNiv: [{ titulo: "Activos", ruta: Api.seccionesActivosListRoute, icon: "fa fa-bug" }
                       , { titulo: "Finalizados", ruta: Api.seccionesCerradosListRoute, icon: "fa fa-bug" }
                      ]
                }
        , {titulo: "Exámenes", icon: "fa fa-bug"}
        , {titulo: "Asistencia", icon: "fa fa-bug"}
        , { titulo: "Facturación", icon: "fa fa-bug"}
    ]
}

export default _menu

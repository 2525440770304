const seccionesCerradosList='/secciones/cerrados'
const seccionesCerradosListRoute='/secciones/cerrados'

const seccionesActivosList='/secciones/activos'
const seccionesActivosListRoute='/secciones/activos'
const materialConteListRoute='/material/contelist'
const materialConteList='/material/contelist'

const materialAvanceTreeRoute='/material/avancetree'
const materialTreeLoad='/material/avancetree'

const materialTreeViewRoute='/material/avancetreehtml'
const materialViewId='/material/materialviewidAlumno'
const materialViewIdOkAlum='/material/materialviewidokalumno'

module.exports = {
  seccionesCerradosList,
  seccionesCerradosListRoute,
  seccionesActivosList,
  seccionesActivosListRoute,

  materialConteListRoute,
  materialConteList,
  materialAvanceTreeRoute,
  materialTreeLoad,

  materialTreeViewRoute,
  materialViewId,
  materialViewIdOkAlum,
}

import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxTree from "global/components/Page/InputHtml/HBoxTree/HBoxTree";

class MaterialTreeList extends HFormUser {
    constructor() {
        super();
        this.state = {
            data: [],
            loading: true,
        };
    }
    componentDidMount() {  this.readApi(); }
    handleChange = name => event => {
        this.setState({ error: "" });
    };
    getKey() {
        return { idRs: this.props.location.state.detIdRs, idCurso: this.props.location.state.detIdCurso, idMateria: this.props.location.state.detIdMateria }
    }
    readApi() {
        //console.log(this.getKey())
        this.setState({ loading: true });
        this.post(Api.materialTreeLoad, this.getKey())
            .then(data => { this.stateHarrys(this, data) })
    }
    btnClickHtml = (row) => {
        let objKey = {
            detKeyItem: row.id
            , detIdRs: this.props.location.state.detIdRs
            , detIdCurso: this.props.location.state.detIdCurso
            , detIdMateria: this.props.location.state.detIdMateria
        }
        apiRuta.toRutaAdd(this, "Html", Api.materialTreeViewRoute, objKey)
    }
    btEnabled = () => { }
    btVolver = () => { apiRuta.toRutaBack(this) }
    arrTit = [{ col: 'descripcion', label: 'Titulo' }
        , { col: 'btnDataVisita', label: 'Vis.', accion: this.btEnabled, width: 60, clsIcono: "ico_visita_reg", clsBoton: "btn btn-success btn-xs" }
        , { col: 'btnDataDwon', label: 'Apr.', accion: this.btEnabled, width: 60, clsIcono: "ico_ok_reg", clsBoton: "col_up_down_reg" }
        , { col: 'btnDataHtml', label: 'Html', accion: this.btnClickHtml, width: 60, clsIcono: "fa fa-sitemap", clsBoton: "col_up_down_cls" }
    ]


    render() {
        if (this.state.loading) return (<ShowLoading />)
        return (<ContainerRightEdit
            tituloContainer={this.props.location.state.detKey}
            contexto={this}
        >
            <HBoxTree key="KeyMat897"
                dataTree={this.state.resObj}
                dataTitulos={this.arrTit}
                expand={false}
            />
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(MaterialTreeList);

//https://maheshsenni.github.io/react-treelist/
//https://www.telerik.com/kendo-react-ui/components/treelist/
//https://js.devexpress.com/Demos/WidgetsGallery/Demo/TreeList/Overview/React/SoftBlue/

import React from 'react';
import ContainerRightError from "../../ContainerRight/ContainerRightError";
import {
  TreeList, Column, Selection,/*  RequiredRule,*/
  Editing, RowDragging, Paging
  , FilterRow, SearchPanel, ColumnChooser
} from 'devextreme-react/tree-list';
import './dx.light.css';
import { Template } from 'devextreme-react/core/template';
/*
const expandedRowKeys = [1];

function ButtonCell(options) {
  return (<div>hola<button >si2</button></div>
  )
  const employee = options.data.Task_Assigned_Employee;
  if (!employee) {
    return <span className="name">not assigned</span>;
  }
  return (
    <React.Fragment>
    <div className="img" style={{ backgroundImage: `url(${employee.Picture})` }} />
    &nbsp;
    <span className="name">{employee.Name}</span>
    </React.Fragment>
    );
  }
  */


class HBoxTree extends React.Component {
  constructor(props) {
    super(props);
    this.data = props;
    this.treeList = React.createRef();
    this.onStateResetClick = this.onStateResetClick.bind(this);
  }
  HtmlCell = (options) => {
    //console.log("options:",options)
    //options.data.html_observ
    return (<div      dangerouslySetInnerHTML={{ __html: options.data[options.column.colTit.col] }} > </div>)
  }
  ButtonCell = (options) => {
    //console.log("options>",options.data.up)
    //https://adminlte.io/themes/AdminLTE/pages/UI/icons.html
    let clName = "fas fa-hand-point-left"
    let clBoton = "btn btn-success btn-xs"
    //console.log("options>",options.column.colTit.icon,"==", clName)
    //return (<a className="btn btn-social-icon btn-bitbucket"><i className="fas fa-bitbucket"></i></a>)
    //console.log("dataIcon",options.column.dataIcon,options.data,options.data[options.column.dataIcon])
    if (options.column.colTit.clsIcono != null)
      if (options.column.colTit.clsIcono.startsWith("ico_")) clName = options.data[options.column.colTit.clsIcono]
      else clName = options.column.colTit.clsIcono

    if (options.column.colTit.clsBoton != null)
      if (options.column.colTit.clsBoton.startsWith("col_")) clBoton = options.data[options.column.colTit.clsBoton]
      else clBoton = options.column.colTit.clsBoton

    if (clName === "") return (<div></div>)
    return (<div>
      <button onClick={() => this.clickBoton(options.columnIndex, options.data.id, options.data)}
        className={clBoton}
        type="button" >
        <i className={clName}></i>
      </button>
    </div>)
  }
  clickBoton = (iCol, idParam, data) => {
    //console.log("tree clickBoton" ,data,iCol,idParam)
    //this.data.btnClickView(iCol,idParam)
    if (this.data.dragLine) this.data.dataTitulos[iCol - 1].accion(data, iCol)
    else this.data.dataTitulos[iCol].accion(data, iCol)
  }

  onStateResetClick() {
    this.treeList.current.instance.state(null);
  }
  reloadPage() {
    window.location.reload();
  }
  dataSource = {
    load: function (loadOptions) {
      console.log("sdfaljklñ")
      //App.xx()
    }
  }
  xx = () => {
    console.log("dslñfjlkñadf")
  }
  onSelectionChanged(e) {
    //console.log("333311")
    //this.data.readLoad()
  }

  onRecursiveChanged(e) {
    //console.log("1111")
  }

  onSelectionModeChanged(e) {
    //console.log("2222")
  }
  clickChange = (e) => {
    //this.data.clickChange(e)
    //window.location.reload();
  }

  //<Selection recursive={recursive} mode="multiple" />
  render() {
    //console.log("this.data.msg",this.data.dataTree.msg)
    //this.data.readLoad()
    //console.log("logdata",this.data.dataTree.rows)
    //dataSource={this.data.dataTree.rows}
    //defaultExpandedRowKeys={expandedRowKeys}
    //defaultSelectedRowKeys={selectedKeys}
    let arrData = []
    if (this.data.dataTree.rows) arrData = this.data.dataTree.rows
    else if (this.data.dataTree) arrData = this.data.dataTree
    //console.log("arrData",arrData,this.data.dataTree.rows)
    //<ContainerRightError data={this.data.dataTree}/>
    return (<div style={{marginLeft:'10px',marginRight:'10px',marginBottom:'10px'}}>
      <TreeList
        id="data222"
        key="prueba1"
        autoExpandAll={this.data.expand}
        dataSource={arrData}
        rootValue={-1}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}

        wordWrapEnabled={true}

        keyExpr="id"
        parentIdExpr="idpadre"
        onSelectionChanged={this.clickChange}
      >
        <ColumnChooser enabled={true} />
        <FilterRow visible={true} />
        <SearchPanel visible={true} />
        <Selection mode="single" />
        {this.data.dataTitulos.map((val, index) => {
          //console.log("val====",val)
          if (val.col.startsWith("html_"))
            return (<Column key={val.col + index}
              caption={val.label}
              dataType="buttons"
              cellTemplate="HtmlTemplate"
              width={val.width}
              colTit={val}
            />)
          else
            if (val.col.startsWith("btn"))
              return (<Column key={val.col + index}
                caption={val.label}
                dataType="buttons"
                cellTemplate="ButtonTemplate"
                width={val.width}
                colTit={val}
              />)
            else
              return <Column key={'cc_' + index}
                dataField={val.col}
                caption={val.label}
                allowSorting={false}
                fixed={true}
                width={val.width}
              />
          //visible={true}
          //sortOrder="asc"
          //dataType="date"
        })}
        <Template name="ButtonTemplate" render={this.ButtonCell} label="kk" />
        <Template name="HtmlTemplate" render={this.HtmlCell} label="kk" />
        {false &&
          <Editing
            mode="popup"
            allowUpdating={false}
            allowDeleting={false}
            allowAdding={true}
          />
        }
        {this.data.dragLine &&
          <RowDragging
            onDragChange={this.onDragChange}
            onReorder={this.onReorder}
            allowDropInsideItem={true}
            allowReordering={true}
          />
        }
        {false &&
          <Paging
            enabled={true}
            defaultPageSize={10}
          />
        }

      </TreeList>
    </div>
    );
  }
  onReorder = (e) => {
    if (this.data.dragLine) this.data.dragLine(e)
    //console.log("Fin DragLine",e.itemData)
  }
  onDragChange = (e) => {
    //console.log("jjajajajajajajaj")
    //console.log("Data",this.data.dragLine)

    /*
      let visibleRows = e.component.getVisibleRows(),
      sourceNode = e.component.getNodeByKey(e.itemData.ID),
      targetNode = visibleRows[e.toIndex].node;
  
    while (targetNode && targetNode.data) {
        if (targetNode.data.ID === sourceNode.data.ID) {
            e.cancel = true;
            break;
        }
        targetNode = targetNode.parent;
    }
    */
  }
}



export default HBoxTree;

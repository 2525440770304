import React, { Component } from "react";
import Api from "../ApiDocente";
import apiRuta from "global/utils/funRuta";
import apiHelp from "global/utils/funHelp";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
class CusosActivos extends HFormUser {

    componentDidMount() {this.readApi(""); }
    readApi = (stParamDict) => {
        this.post(Api.seccionesActivosList, { qryFiltro: stParamDict })
            .then(data => { this.stateHarrys(this, data) })
    }
    startSearch = bus => { this.readApi(apiHelp.getTextSearch()) }
    startCrear = iReg => { this.edit(-1) };
    edit = row => {
        apiRuta.toRutaAdd(this, "Contenido", Api.materialConteListRoute, { detKey: row.name })
    };
    btMaterial = row => {
        apiRuta.toRutaAdd(this, "Contenido", Api.materialConteListRoute, { detIdRs: row.name, detIdCurso: row.cur_id })
    };
    btVolver = () => { apiRuta.toRutaBack(this) }
    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "ram_sigsec", label: "Sigla", align: "left" }
            , { id: "ram_descrip", label: "Descripción", align: "left" }
            , { id: "fab", label: "Periodo", align: "right", swFiltro: false }
            //, { id:"bt_Asig", label:"Ed",align:"center",accion:this.edit ,clsIcono:"fas fa-clipboard-check"}
            , { id: "bt_Mate", label: "Contenidos", align: "center", accion: this.btMaterial, clsIcono: "fas fa-sitemap" }
        ]
        return (<ContainerRightTable
            tituloContainer='Mis Cursos Activos'
            titulos={titulos}
            resObj={this.state.resObj}
            contexto={this}
        >
        </ContainerRightTable>
        );
    }

}
export default WithNavigate(CusosActivos);
import React,{Component} from "react";
import { Route } from "react-router-dom";
import HMainRouter  from "global/components/Page/MenuLTE/HMainRouter";

import Api  from "modAlumno/ApiDocente";

import CursosActivosList from "modAlumno/secciones/CursosActList";
import CursosCerradosList from "modAlumno/secciones/CursosCerrList";
import CursosActConteList from "modAlumno/secciones/CursosActConteList";

import MaterialAvanceTreeList from "modAlumno/material/AvanceTreeList";
import MaterialAvanceTreeHtml from "modAlumno/material/AvanceTreeHtml";

class MainRouterMod  extends Component{
   constructor(props) {
      super(props);
      this.data=props;
    }   
    render() {
       return (
          <HMainRouter>
               <Route exact path={Api.seccionesActivosListRoute} element={<CursosActivosList/>} />
               <Route exact path={Api.seccionesCerradosListRoute} element={<CursosCerradosList/>} />
               <Route exact path={Api.materialConteListRoute} element={<CursosActConteList/>} />

               <Route exact path={Api.materialAvanceTreeRoute} element={<MaterialAvanceTreeList/>} />
               <Route exact path={Api.materialTreeViewRoute} element={<MaterialAvanceTreeHtml/>} />

         </HMainRouter>
         );
   }
}
export default MainRouterMod;